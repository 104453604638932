
export default ['$q', ($q) => {
    let bankAccountData = {
        accountHolderName: '',
        bankAccountNumber: '',
        sortCode: '',
        bankAccountType: '',
        isAuthorisedPayment: undefined,
    };

    return {
        getBankAccountData() {
            return bankAccountData;
        },

        setBankAccountData(data) {
            bankAccountData = {...data};
        },
    };
}];