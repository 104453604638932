import templateStr from 'text!./pm-bank-details.html';

export default ['BankAccountService', (BankAccountService) => {
    const SORT_CODE_REGEX = /^\d{6}$/;
    return {
        restrict: 'E',
        scope: {
            bankAccountData: '='
        },
        template: templateStr,
        controller: ['$scope', '$translate', ($scope, $translate) => {
            let ACCOUNT_NUMBER_ERROR = 'and.quoteandbind.pm.directives.templates.pm-bank-details.validation.AccountNumber';
            let SORT_CODE_ERROR = 'and.quoteandbind.pm.directives.templates.pm-bank-details.validation.SortCode';
            let INT_OFFLINE = 'and.quoteandbind.pm.directives.templates.pm-bank-details.validation.IntegrationOffline';
            $translate([ACCOUNT_NUMBER_ERROR, SORT_CODE_ERROR, INT_OFFLINE])
                .then((translations) => {
                    ACCOUNT_NUMBER_ERROR = translations[ACCOUNT_NUMBER_ERROR];
                    SORT_CODE_ERROR = translations[SORT_CODE_ERROR];
                    INT_OFFLINE = translations[INT_OFFLINE];
                });

            $scope.accountNameTooltip = {
                content: 'and.quoteandbind.pm.views.paymentstep.2.monthly.tooltip.Account Name',
                trigger: 'click'
            };
            $scope.accountNumberTooltip = {
                content: 'and.quoteandbind.pm.views.paymentstep.2.monthly.tooltip.Account Number',
                trigger: 'click'
            };
            $scope.sotcodeTooltip = {
                content: 'and.quoteandbind.pm.views.paymentstep.2.monthly.tooltip.sort code',
                trigger: 'click'
            };

            // Bank Account details VMs
            // Account Number
            $scope.bankAccountNumberVM = {
                get value() {
                    return $scope.bankAccountData.bankAccountNumber.value;
                },
                set value(newValue) {
                    $scope.bankAccountData.bankAccountNumber.value = newValue;
                    if ($scope.bankAccountData.validationResponse_itb.value) { // Clear validation results
                        $scope.bankAccountData.validationResponse_itb.value = undefined;
                    }
                },
                aspects: {
                    inputCtrlType: 'text',
                    required: $scope.bankAccountData.bankAccountNumber.aspects.required,
                    get valid() {
                        return $scope.bankAccountData.bankAccountNumber.aspects.valid &&
                            (!$scope.bankAccountData.validationResponse_itb.value ||
                                $scope.bankAccountData.validationResponse_itb.value.isCorrect === true);
                    },
                    get validationMessages() {
                        if ($scope.bankAccountData.bankAccountNumber.aspects.valid !== true) {
                            return $scope.bankAccountData.bankAccountNumber.aspects.validationMessages;
                        } else if (!$scope.bankAccountData.validationResponse_itb.value || $scope.bankAccountData.validationResponse_itb.value.integrationOffline === true) {
                            return [INT_OFFLINE];
                        } else if ($scope.bankAccountData.validationResponse_itb.value.isCorrect !== true) {
                            return [ACCOUNT_NUMBER_ERROR];
                        }
                        return [];
                    }
                }
            };
            // Sort Code
            $scope.sortCodeVM = {
                get value() {
                    return $scope.bankAccountData.sortCode.value;
                },
                set value(newValue) {
                    $scope.bankAccountData.sortCode.value = newValue;
                    if ($scope.bankAccountData.validationResponse_itb.value) { // Clear validation results
                        $scope.bankAccountData.validationResponse_itb.value = undefined;
                    }
                },
                aspects: {
                    inputCtrlType: 'text',
                    required: $scope.bankAccountData.sortCode.aspects.required,
                    get valid() {
                        return $scope.bankAccountData.sortCode.aspects.valid &&
                            (!$scope.bankAccountData.validationResponse_itb.value ||
                                $scope.bankAccountData.validationResponse_itb.value.isCorrect === true);
                    },
                    get validationMessages() {
                        if ($scope.bankAccountData.sortCode.aspects.valid !== true) {
                            return $scope.bankAccountData.sortCode.aspects.validationMessages;
                        } else if (!$scope.bankAccountData.validationResponse_itb.value || $scope.bankAccountData.validationResponse_itb.value.integrationOffline === true) {
                            return [INT_OFFLINE];
                        } else if ($scope.bankAccountData.validationResponse_itb.value.isCorrect !== true) {
                            return [SORT_CODE_ERROR];
                        }
                        return [];
                    }
                }
            };
            // CBPUAT-1199: Automatically insert dashes into SortCode field, but only if input is 6 numbers. That
            // prevents an infinite loop while keeping the solution simple. We don't (want to) have to handle scenarios
            // like 12-3456 for instance, as the user is instructed to write 6 digits only.
            $scope.$watch(() => $scope.sortCodeVM.value, (newValue) => {
                if (!!newValue && SORT_CODE_REGEX.test(newValue)) {
                    $scope.sortCodeVM.value = `${newValue.substring(0, 2)}-${newValue.substring(2, 4)}-${newValue.substring(4)}`;
                }
            });

            $scope.$watch(() => $scope.bankAccountData.value, () => {
                if ($scope.bankAccountData.value) {
                    BankAccountService.setBankAccountData($scope.bankAccountData.value);
                }
            }, true);
        }]
    };
}];